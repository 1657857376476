import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { css } from '@emotion/react'
import ShareSidebar from '@/components/app/ShareSidebar'
import Header from '@/components/app/Header'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { SHARE_STATUS } from '@/constants/access';
import { useGetShareHubQuery } from '@/store/slices/hub';

const ShareLayout = () => {
  const hubId = window.location.pathname.split('/').at(-1);
  const { data } = useGetShareHubQuery({ hubId });
  const shareStatus = data?.shareStatus;

  const email = useSelector(state => state.app.user?.email);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const headerHeight = matches ? 64 : 56;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const layoutCss = {
    main: css({
      display: 'flex',
      height: `calc(100vh - ${headerHeight}px)`,
      width: '100vw',
      overflow: 'hidden'
    })
  }

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  return (
    <>
      <Header handleDrawerToggle={handleDrawerToggle} email={email} />
      {(shareStatus === SHARE_STATUS.PUBLIC_LINK ||
        shareStatus === SHARE_STATUS.PUBLIC_LINK_TOKEN) && (
        <main css={layoutCss.main}>
          <ShareSidebar
            shareStatus={shareStatus}
            handleDrawerClose={handleDrawerClose}
            handleDrawerTransitionEnd={handleDrawerTransitionEnd}
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />
          <Outlet />
        </main>
      )}
    </>
  );
}
export default ShareLayout
