import { useFormik } from 'formik';
import * as yup from 'yup';
import VisualImg from '@/assets/portal/portal-visual.png';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useLoginMutation } from 'slice/app';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation('portal');
  const [login, { isLoading }] = useLoginMutation();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('validation.email.invalid'))
        .required(t('validation.email.required')),
      password: yup
        .string()
        .min(8, t('validation.password.minLength'))
        .required(t('validation.required', {field: t('field.password')})),
    }),
    onSubmit: ({ email, password }) => login({ email, password })
  });

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={6}
        sx={{
          backgroundImage: `url(${VisualImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={6} component={Paper} sx={{ display: 'flex' }}>
        <Box sx={{ maxWidth: '430px', mx: [4, 'auto'], my: 'auto' }}>
          <Typography component="h1" variant="h4" gutterBottom sx={{alignSelf: 'flex-start'}}>
            {t('login.title')}
          </Typography>
          <Typography paragraph>
            {t('login.welcome')}<br />{t('login.callToAction')}
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              margin="normal"
              required
              id="email"
              name="email"
              label={t('field.email')}
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              margin="normal"
              required
              id="password"
              name="password"
              label={t('field.password')}
              type="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Button type="submit" variant="contained" size="large" fullWidth sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}>
              {t('button.login')}
            </Button>
            
            <Grid container>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary" align="center">
                  <Link href="/signUp" color="inherit">
                    {t('button.signup')}
                  </Link> &nbsp;|&nbsp;
                  <Link
                    color="inherit"
                    href={t('privacyPolicy.link')}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t('privacyPolicy.text')}
                  </Link>
              </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;